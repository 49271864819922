<script setup>
import { onMounted, provide, watch, ref } from 'vue'
import { useRoute } from 'vue-router'
import TvbLayout from './layout/index.vue'
import { useWindowSize } from '@vueuse/core'
import { TvbConfig } from '@tvb-sz/tvb-headerfooter-frontend'
import '@tvb-sz/tvb-headerfooter-frontend/theme-default/index.css'
import { useAppStore } from './stores/app'
defineOptions({ name: 'TvbApp' })

const { width, height } = useWindowSize()

const app = useAppStore()
const size = ref(null)
const env = import.meta.env.VITE_ENV
const setWindowSize = () => {
  document.getElementById('tvb').style.setProperty('--window-width', `${width.value}px`)
  document.getElementById('tvb').style.setProperty('--window-height', `${height.value}px`)
  document
    .getElementById('tvb')
    .style.setProperty(
      '--header-height',
      `${document.querySelector('#tvb > .tvb-container .tvb-header')?.clientHeight || 0}px`
    )
  document
    .getElementById('tvb')
    .style.setProperty(
      '--footer-height',
      `${document.querySelector('#tvb > .tvb-container .tvb-footer')?.clientHeight || 0}px`
    )

  const sizeMap = {
    xl: [Infinity, 1201],
    l: [1200, 1025],
    m: [1024, 768],
    s: [767, 391],
    xs: [390, -Infinity]
  }
  for (const key in sizeMap) {
    const [max, min] = sizeMap[key]
    if (max >= width.value && width.value >= min) {
      size.value = key
      break
    }
  }
}

const route = useRoute()
provide('width', width)
provide('height', height)
provide('setWindowSize', setWindowSize)
provide('size', size)

const roomqCallback = (state, url) => {
  window.console.log('roomqCallback', state, url)
  switch (state) {
    // -3
    case window.RoomQ.serving:
      break
    // -4
    case window.RoomQ.in_queue:
      break
    // 0
    case window.RoomQ.serverError:
      break
  }

  return true
}

const roomId = import.meta.env.VITE_ROOMQ_ID
const NOQ_SRC =
  `https://scripts.noq.com.hk/v2.0.8/noq-vwr.min.js?c=${roomId}&callback=roomqCallback&noq_r=false&noq_v=false`
const initVoteQueue = () => {
  if (app.config.vote_queue !== 1) {
    return
  }
  if (window?.RoomQ) {
    window.console.log('Roomq ticket checking...')
    window.RoomQ.checkTicket()
    return
  }
  window.console.log('Loading roomq script...')
  window.roomqCallback = roomqCallback
  const script = document.createElement('script')
  script.setAttribute('type', 'text/javascript')
  script.setAttribute('src', NOQ_SRC)
  document.getElementsByTagName('head')[0].appendChild(script)
}
watch(
  () => route.name + route.fullPath,
  () => {
    initVoteQueue()
  }
)

onMounted(() => {
  setWindowSize()
  setTimeout(setWindowSize, 300)
})

watch([width, height], () => setWindowSize())
</script>

<template>
  <tvb-config :env="env">
    <tvb-layout>
      <router-view />
    </tvb-layout>
  </tvb-config>
</template>

<style lang="scss">
.tvb-main {
  min-height: calc(100vh - 215px - 64px);
}
</style>

import { createRouter, createWebHistory } from 'vue-router'
import termsRouters from './terms'
import faqRouters from './faq'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'HomePage',
      component: () => import('../views/Home/home.vue'),
      meta: {
        layerData: {
          screenID: 'S001'
        }
      }
    },
    {
      path: '/search',
      name: 'search',
      component: () => import('../views/Search/index.vue'),
      meta: {
        layerData: {
          screenID: 'S005'
        }
      }
    },
    {
      path: '/qrcode',
      name: 'qrcode',
      component: () => import('../views/Qrcode/index.vue'),
      meta: {
        layerData: {
          screenID: 'S005'
        }
      }
    },
    {
      path: '/:categoryPath([a-z|0-9|-]+-c)/:categoryTitle?',
      name: 'category',
      component: () => import('../views/Article/Category/index.vue'),
      meta: {
        layerData: {
          screenID: ''
        }
      }
    },
    {
      path: '/:categoryPath([a-z|0-9|-]+)?/:articleId(\\d{7})',
      name: 'detail',
      component: () => import('../views/Article/Detail/index.vue'),
      meta: {
        layerData: {
          screenID: 'S004'
        }
      }
    },
    {
      path: '/:categoryPath([a-z|0-9|-]+)?/:pollingId(p\\d{6}$)',
      name: 'vote',
      component: () => import('../views/Article/Vote/index.vue'),
      meta: {
        layerData: {
          screenID: 'S004'
        }
      }
    },
    {
      path: '/preview/article/:articleId',
      name: 'articlePreview',
      component: () => import('../views/Article/Detail/index.vue'),
      meta: { title: '文章詳情預覽', preview: true }
    },
    {
      path: '/preview/vote/:pollingId',
      name: 'votePreview',
      component: () => import('../views/Article/Vote/index.vue'),
      meta: { title: '活动投票預覽', preview: true }
    },
    {
      path: '/terms',
      name: 'Terms',
      redirect: '/terms/index.html',
      component: () => import('@/views/Terms/index.vue'),
      children: termsRouters,
      meta: { canonical: '/terms/' }
    },
    {
      path: '/info',
      name: 'Info',
      component: () => import('@/views/Info/index.vue'),
      redirect: '/info/faq/corporation.html',
      children: [
        {
          path: 'faq',
          name: 'Faq',
          redirect: '/info/faq/corporation.html',
          component: () => import('@/views/Info/Faq/index.vue'),
          meta: { canonical: '/info/faq/', language: 'tc' },
          children: faqRouters
        },
        {
          path: 'enquiry',
          name: 'Enquiry',
          redirect: '/info/enquiry/index.html',
          component: () => import('@/views/Info/Enquiry/index.vue'),
          meta: { canonical: '/info/enquiry/', language: 'tc' },
          children: [
            {
              path: 'index.html',
              name: 'EnquiryIndexTc',
              component: () =>
                import('@/views/Info/Enquiry/Index/index.vue').catch(() =>
                  window.location.reload()
                ),
              meta: { language: 'tc' }
            },
            {
              path: 'e_index.html',
              name: 'EnquiryIndexEn',
              component: () =>
                import('@/views/Info/Enquiry/Index/index.vue').catch(() =>
                  window.location.reload()
                ),
              meta: { language: 'en' }
            },
            {
              path: 'enquiry_form',
              name: 'EnquiryFormTc',
              component: () =>
                import('@/views/Info/Enquiry/EnquiryForm/index.vue').catch(() =>
                  window.location.reload()
                ),
              meta: { language: 'tc' }
            },
            {
              path: 'e_enquiry_form',
              name: 'EnquiryFormEn',
              component: () =>
                import('@/views/Info/Enquiry/EnquiryForm/index.vue').catch(() =>
                  window.location.reload()
                ),
              meta: { language: 'en' }
            }
          ]
        }
      ]
    },
    {
      path: '/bigbigchannel',
      name: 'BigBigChannel ',
      component: () =>
        import('@/views/BigBigChannel/index.vue').catch(() => window.location.reload()),
      meta: { title: 'big big channel 停止服務' }
    },
    {
      path: '/notice.html',
      name: 'error404',
      component: () => import('../views/Error/404.vue'),
      meta: { noRobots: true }
    },
    {
      path: '/404.html',
      redirect: { name: 'error404' }
    },
    {
      path: '/500.html',
      name: 'error500',
      component: () => import('../views/Error/500.vue'),
      meta: { noRobots: true }
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: { name: 'error404' }
    }
  ],
  scrollBehavior(to, __, savedPosition) {
    if (['detail', 'articlePreview', 'vote', 'votePreview'].includes(to?.name)) return { top: 0 }
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ top: savedPosition?.top ?? 0 })
      }, 500)
    })
  }
})

router.beforeEach((to) => {
  const categoryTitle = to?.params?.categoryTitle
  const redirectDetail = (id, name = 'detail') => {
    let othParams = { articleId: id }
    if (name === 'vote') othParams = { pollingId: id }
    return {
      ...to,
      name: name || 'detail',
      params: { categoryPath: to?.params?.categoryPath, ...othParams }
    }
  }
  if (to?.name === 'category' && categoryTitle) {
    if (categoryTitle.length === 7) {
      // /artiste-news-c/1234567
      if (/^\d+$/.test(categoryTitle)) {
        return redirectDetail(categoryTitle)
      }
      // /voting-c/p123456
      if (/^p\d{6}$/.test(categoryTitle)) {
        return redirectDetail(categoryTitle, 'vote')
      }
    } else {
      const index = categoryTitle.lastIndexOf('-')
      if (index > 0) {
        const res = categoryTitle.slice(index + 1)
        if (res && res.length === 7) {
          // /artiste-news-c/林家謙澳門演唱會帶歌迷遁地上天-增設Encore環節歌迷即場點歌-1234567
          if (/^\d+$/.test(res)) {
            return redirectDetail(res)
          }
          // /voting-c/萬千星輝頒獎典禮2023觀眾投票-p123456
          if (/^p\d{6}$/.test(res)) {
            return redirectDetail(res, 'vote')
          }
        }
      }
    }
  }
})

export default router
